html, body {
  margin: 0;
  font-family: Roboto !important; }

a {
  text-decoration: none;
  color: #2f628a; }

.mb-1 {
  margin-bottom: 1rem; }

.mb-2 {
  margin-bottom: 2rem; }

.mb-3 {
  margin-bottom: 3rem; }

.mb-4 {
  margin-bottom: 4rem; }

.mb-5 {
  margin-bottom: 5rem; }

.space {
  margin-top: 10px !important;
  margin-bottom: 10px !important; }

.spaceBig {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.spaceBigX {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.spaceLeft {
  margin-left: 30px !important; }

.spaceBottom {
  margin-bottom: 20px !important; }

.spaceBottomX {
  margin-bottom: 40px !important; }

.padding {
  padding: 20px !important; }

.padding-small {
  padding: 5px !important; }

.b-right {
  border-right: 1px solid #f6f6f6; }

.b-bottom {
  border-bottom: 1px solid #f6f6f6; }

.p-top {
  padding-top: 5px; }

.p-bottom {
  padding-bottom: 5px; }

.m-left {
  margin-left: 20px !important; }

.header-text {
  text-align: center;
  font-size: 28px !important;
  font-weight: 500 !important; }

.blue {
  color: #2f628a !important; }

.green {
  color: #97CE68 !important; }

.red {
  color: #e75926 !important; }

.lightblue {
  color: #609edf !important; }

.white {
  color: #ffffff !important; }

.center {
  text-align: center; }

.bold {
  font-weight: bold !important; }

.dim {
  color: #666 !important; }

.small {
  font-size: 10px !important; }

.banner {
  width: 100%; }

.loading {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 80vh;
  padding-top: 10vh;
  width: 100%; }

.loading-short {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 30vh;
  padding-top: 10vh;
  width: 100%; }

.success-alert {
  background-color: #2f628a !important;
  padding: 20px !important;
  color: white !important;
  border-radius: 3px; }

.error-alert {
  background-color: #e75926 !important;
  padding: 20px !important;
  color: white !important;
  border-radius: 3px; }

.charts {
  width: 100%;
  height: 300px !important; }

table thead th {
  font-weight: bold !important;
  color: black !important;
  font-size: 17px !important; }

#sidebar .MuiListItemIcon-root {
  min-width: 0 !important;
  margin-right: 17px; }

#sidebar a {
  display: flex;
  align-items: center; }

#login {
  background: #f2f2f2;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }
  #login #paper {
    width: 40%; }

#app {
  flex-grow: 1;
  padding: 25px 0;
  min-height: 100vh;
  background: #f2f2f2;
  overflow: hidden; }

.ql-snow .ql-tooltip {
  left: 10px !important; }

.ql-editor img {
  width: 100% !important; }

.ql-editor .ql-video {
  width: 100% !important;
  height: 500px !important; }

.ql-font span[data-value="roboto"]::before {
  font-family: 'Roboto';
  content: 'Roboto' !important; }

.ql-font span[data-value="open-sans"]::before {
  font-family: 'Open Sans';
  content: 'Open Sans' !important; }

.ql-font-open-sans {
  font-family: "Open Sans"; }

.ql-font-roboto {
  font-family: "Roboto"; }

.ql-toolbar.ql-snow {
  font-family: "Roboto" !important; }

.dialog-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

.template {
  text-align: center;
  width: 21%;
  display: inline-block;
  vertical-align: text-top;
  border-radius: 5px;
  padding: 5px;
  margin: 10px;
  border: 2px solid transparent;
  cursor: pointer; }

.v-align-center {
  display: flex;
  align-items: center; }

.xy-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.btn-full {
  width: 100%;
  height: 100%;
  font-size: large !important; }

.btn-danger {
  background-color: deeppink !important;
  color: white !important; }

.w-100 {
  width: 100% !important; }

.font-larger {
  font-size: larger !important; }

.signer-container {
  position: relative;
  width: 100%;
  margin: 10px 0; }

canvas.signer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%; }

canvas.signer.edit {
  cursor: crosshair; }

.h-fit-content {
  height: fit-content; }

.preview-container {
  max-height: 700px;
  overflow-y: scroll;
  background: #ebebeb;
  border: 1px solid #ebebeb;
  padding: 0 10px !important; }

.preview-container::-webkit-scrollbar {
  width: 10px; }

/* Track */
.preview-container::-webkit-scrollbar-track {
  background: #f1f1f1; }

/* Handle */
.preview-container::-webkit-scrollbar-thumb {
  background: #609edf; }

/* Handle on hover */
.preview-container::-webkit-scrollbar-thumb:hover {
  background: #2f628a; }

.pos-rel {
  position: relative; }

.page-number {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 24pt;
  font-weight: bolder;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center; }

.page-number-item {
  background: rgba(33, 33, 33, 0.8);
  color: white;
  width: 15%;
  text-align: center;
  border-radius: 10px;
  padding: 10px 5px; }

.MuiLinearProgress-barColorPrimary {
  background-color: deeppink !important; }
